/* Demo 3 Homepage 3 */
/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800");
/* font-family: 'Poppins', sans-serif; */
.footer-bottom .container.no-before:before {
  height: 0 !important; }

.sticky-header.fixed.header-middle .menu > li > a {
  padding-top: 4rem;
  padding-bottom: 3.1rem; }
  .sticky-header.fixed.header-middle .menu > li > a:after {
    top: 55%; }

.header.header-8 .logo {
  margin-top: 3.7rem;
  margin-bottom: 3.1rem; }

.header.header-8 .menu > li > a {
  padding-top: 4rem;
  padding-bottom: 3.1rem; }
  .header.header-8 .menu > li > a:after {
    top: 55%; }

.header.header-8 .header-top {
  background-color: #fff; }
  .header.header-8 .header-top a {
    color: #777;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 300; }
    .header.header-8 .header-top a:hover, .header.header-8 .header-top a:focus {
      color: var(--primary-color, #445f84); }

.header.header-8 .header-middle .container::after,
.header.header-8 .header-middle .container-fluid::after {
  content: '';
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 0;
  height: .1rem;
  background-color: #ebebeb; }

.header.header-8 .top-menu li + li {
  margin-left: 2.5rem; }

.header.header-8 .header-search .header-search-wrapper {
  top: 60%;
  display: none; }

.header.header-8 .header-search .header-search-wrapper.show {
  display: flex; }

.header.header-8 .header-dropdown + .header-dropdown {
  margin-left: 3rem; }

.header.header-8 .cart-dropdown {
  padding-top: .8rem; }

.header.header-8 .header-search,
.header.header-8 .mobile-menu-toggler {
  padding-top: 1.2rem; }

.header.header-8 .top-menu:not(.top-link-menu) ul {
  min-width: 180px; }

.title-lg {
  font-weight: 400; }

.heading.heading-center .title-lg {
  margin-bottom: 1.4rem; }

.intro-slider-container .intro-content .btn-white-primary {
  color: var(--white, #fff); }
  .intro-slider-container .intro-content .btn-white-primary > span {
    color: var(--white, #fff); }

.intro-slider.owl-simple.owl-nav-inside .owl-dots {
  bottom: 25px; }

.intro-slider-container,
.intro-slide,
.banner {
  background-color: #e6e6e6; }

.intro-slider-container:before,
.intro-slider .slide-image:before {
  padding-top: 42.73504%; }

@media screen and (max-width: 479px) {
  .intro-slider-container:before,
  .intro-slider .slide-image:before {
    padding-top: 83.33333%; } }

.intro-content {
  margin-top: -1rem; }
  .intro-content .btn i:last-child {
    font-size: 1.5rem;
    margin-left: 2.5rem; }

.intro-subtitle {
  margin-bottom: .5rem;
  color: #ebebeb; }

.intro-title {
  font-weight: 600;
  letter-spacing: -.03em;
  line-height: 1.1;
  margin-bottom: .2rem; }

.intro-price {
  color: #333333;
  font-weight: 300;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -.03em;
  margin-bottom: 1.3rem; }

.icon-boxes-container {
  padding-top: .45rem;
  padding-bottom: 3rem; }

.icon-box-side {
  padding-left: .5rem; }
  .icon-box-side .icon-box-icon {
    font-size: 4rem; }
  .icon-box-side .icon-box-title {
    text-transform: uppercase; }
  .icon-box-side p {
    letter-spacing: -.01em; }

.banner-text a {
  color: inherit; }
  .banner-text a:hover, .banner-text a:focus {
    text-decoration: none; }

.banner-group .banner-subtitle.bright-black {
  color: #666; }

.banner-group .banner-subtitle {
  font-weight: 300;
  letter-spacing: -.01em;
  margin-bottom: .6rem;
  color: #ebebeb; }

.banner-group .banner-title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.25;
  margin-bottom: .1rem;
  letter-spacing: -.01em; }

.banner-group .banner-text {
  color: #333333;
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 1.25;
  letter-spacing: -.025em; }

.banner-group .banner-link {
  margin-top: 1.4rem;
  padding: .8rem 2rem; }

.banner-group .btn {
  min-width: 110px; }

.banner-group .banner-large .banner-content {
  left: 3rem; }

.banner-group .banner-middle .banner-content {
  left: 2.5rem; }

.banner-group .banner-content {
  left: 30px; }
  .banner-group .banner-content.banner-content-bottom {
    bottom: 40px; }
  .banner-group .banner-content.banner-content-right {
    left: auto;
    right: 25px; }

.banner-content-overlay {
  padding-top: 1.1rem; }

.tab-pane-shadow {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.03); }

.tab-pane .owl-carousel .owl-item:not(:first-child) .product {
  border-left: .1rem solid #eeeeee; }

.tab-pane .owl-simple .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6rem; }

.nav.nav-pills .nav-link {
  font-size: 1.4rem; }

/* Product Product-3 */
.product.product-3:hover .product-media::before, .product.product-3:focus .product-media::before {
  opacity: .2; }

.product.product-3 .product-media::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: #999;
  transition: all .3s; }

.product.product-3 .product-body {
  padding: 1.6rem 1rem 0; }

.product.product-3 .product-cat {
  font-weight: 400;
  font-size: 1.5rem;
  color: #ccc;
  margin-bottom: 1rem; }
  .product.product-3 .product-cat a {
    transition: all .35s ease; }
    .product.product-3 .product-cat a:hover, .product.product-3 .product-cat a:focus {
      color: #777;
      box-shadow: 0 1px 0 #bcbcbc; }

.product.product-3 .product-title {
  font-size: 1.5rem;
  line-height: 1.3;
  margin-bottom: 1.2rem; }
  .product.product-3 .product-title a {
    box-shadow: none;
    transition: all .3s; }
  .product.product-3 .product-title:hover a, .product.product-3 .product-title:focus a {
    box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.product.product-3 .product-price {
  color: #333;
  font-weight: 500;
  margin-bottom: 0; }

.product.product-3 .product-body {
  padding-bottom: 1.8rem;
  transition: transform .35s ease; }

.product.product-3 .product-footer {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  transform: translateY(100%);
  visibility: hidden;
  transition: all .35s ease;
  padding: 0; }

.product.product-3 .product-action {
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  background-color: transparent;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-top: .1rem solid #ebebeb;
  border-bottom: none;
  margin-top: 2rem; }

.product.product-3 .btn-product {
  font-weight: 400;
  padding-top: .1rem;
  padding-bottom: .1rem;
  text-transform: uppercase; }

.product.product-3 .btn-product:not(:hover):not(:focus) span {
  color: var(--primary-color, #445f84); }

.product.product-3 .btn-product + .btn-product {
  border-left-style: solid; }

.product-countdown-container {
  background-color: #f7f7f7;
  border-radius: 0; }
  .product-countdown-container .product-contdown-title {
    color: #777; }
  .product-countdown-container .product-countdown .countdown-amount {
    text-transform: capitalize;
    color: var(--primary-color, #445f84); }

.products .row {
  margin-left: 0;
  margin-right: 0; }
  .products .row > .col,
  .products .row > [class*=col-] {
    padding-left: 0;
    padding-right: 0; }

.products .product {
  margin-bottom: 0;
  border-bottom: .1rem solid #eee; }

.blog-posts .entry-media {
  margin-bottom: 1.5rem; }

.blog-posts .entry-content p {
  margin-bottom: .6rem; }

.blog-posts .entry-meta {
  margin-bottom: .3rem; }

.blog-posts .entry-title {
  font-weight: 400;
  font-size: 2rem;
  margin-bottom: .9rem; }

.cta .input-group {
  margin-bottom: .5rem; }

.cta .form-control {
  color: #333;
  border-color: #f4f4f4;
  background-color: #f4f4f4;
  border-right-width: 0; }
  .cta .form-control::placeholder {
    font-style: italic; }

.cta .input-group-round {
  border-radius: 30px; }
  .cta .input-group-round .btn {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; }

.cta-title {
  font-size: 2.4rem;
  letter-spacing: -.01em;
  font-weight: 600; }

.cta-heading {
  margin-bottom: 2.5rem; }
  .cta-heading .cta-title {
    margin-bottom: .3rem; }

.cta-desc {
  font-size: 1.6rem; }

.cta-horizontal {
  padding: 4.5rem 3rem 4.5rem; }
  .cta-horizontal .cta-title {
    font-size: 2.2rem;
    margin-bottom: .8rem;
    letter-spacing: -.01em;
    font-weight: 600; }
  .cta-horizontal .cta-desc {
    color: #666;
    font-size: 1.4rem;
    font-weight: 400; }

.cta .btn:not(.btn-block) {
  min-width: 160px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.4rem; }

.font-weight-medium {
  font-weight: 500; }

.blog-posts .read-more {
  font-size: 1.4rem; }

.entry-content {
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.footer .footer-middle {
  padding-top: 5.5rem; }

.footer .footer-bottom .container {
  padding-bottom: 2rem; }

@media screen and (min-width: 576px) {
  .products [class*=col-] {
    border-right: .1rem solid #eee; }
    .products [class*=col-]:nth-child(2n) {
      border-right-width: 0; }
  .cta .input-group {
    background-color: #f4f4f4; }
  .cta-horizontal .btn:not(.btn-block) {
    min-width: 270px; } }

@media screen and (min-width: 768px) {
  .intro-subtitle {
    margin-bottom: 1rem; }
  .intro-price {
    font-size: 2.4rem;
    margin-bottom: 1.8rem; }
  .products [class*=col-]:nth-child(2n) {
    border-right-width: .1rem; }
  .products [class*=col-]:nth-child(3n) {
    border-right-width: 0; }
  .cta-horizontal {
    padding-left: 5rem;
    padding-right: 5rem; }
  .banner-group .banner-large .banner-content {
    left: 7rem; }
  .banner-group .banner-middle .banner-content {
    left: 5rem; } }

@media screen and (min-width: 992px) {
  .intro-slider.owl-simple.owl-nav-inside .owl-dots {
    bottom: 30px; }
  .intro-content .btn {
    min-width: 150px; }
  .intro-subtitle {
    margin-bottom: 1.4rem; }
  .intro-title {
    font-size: 5.2rem; }
  .intro-price {
    font-size: 2.8rem;
    margin-bottom: 2.3rem; }
  .products [class*=col-]:nth-child(3n) {
    border-right-width: .1rem; }
  .products [class*=col-]:nth-child(4n) {
    border-right-width: 0; }
  .cta-horizontal {
    padding-left: 7rem;
    padding-right: 7rem; } }

@media screen and (min-width: 1200px) {
  .intro-content .btn {
    min-width: 170px; }
  .intro-price {
    font-size: 3rem; }
  .products [class*=col-]:nth-child(4n) {
    border-right-width: .1rem; }
  .products [class*=col-]:nth-child(5n) {
    border-right-width: 0; } }

@media screen and (min-width: 1200px) {
  .header-8 .main-nav {
    margin-right: 35.5rem; } }
