.user-form #cemail {display: none;}
@media (max-width: 991px) {
    .sticky-header__wrapper-will-be-hidden-on-max-width-991 { display: none !important; }
}
/* Sidebar filter */
@media screen and (min-width: 992px) {
    .sidebar-shop .widget {
        border-bottom: 0px
    }
    .widget-select .widget-body,
    .widget-select select { margin: 0px; padding: 0px; }
    .widget .filter-price {
        padding-bottom: 0px;
    }
    .widget-title-select > a::after { display: none !important; }
    .widget-select .collapse { display: block !important; }
}

.btn-wishlist.active:before {
    content: '\f233'
}

.category-description.container {
    padding-top: 0;
}
.category-description p {
    color: #000;
}

/* Whatsapp button */
.whatsapp-button .outer {
    position: relative;
    width: 100%;
    height: 100%;
}
.whatsapp-button .inner {
    text-align: center;
    margin: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.whatsapp-button {
    background: #25D366;
    border: 3px solid #1cc15a;
    border-radius: 50%;
    box-shadow: 0 8px 10px rgba(7, 206, 112, 0.6);
    cursor: pointer;
    height: 56px;
    text-align: center;
    width: 56px;
    position: fixed;
    z-index: 9999;
    transition: .3s;
    -webkit-animation: hoverWave linear 1s infinite;
    animation: hoverWave linear 1s infinite;
}
/*.whatsapp-button .text-button {
      height:68px;
      width:68px;
      border-radius:50%;
      position:relative;
      overflow:hidden;
  }
.whatsapp-button .text-button span {
    text-align: center;
    color:#23a455;
    opacity: 0;
    font-size: 0;
    position:absolute;
    right: 8px;
    top: 27px;
    line-height: 14px;
    font-weight: 600;
    transition: opacity .3s linear;
    font-family: 'montserrat', Arial, Helvetica, sans-serif;
}
.whatsapp-button .text-button:hover span {
    opacity: 1;
    font-size: 11px;
}
.whatsapp-button:hover i {
    display:none;
}
.whatsapp-button:hover {
    z-index:1;
    background:#fff;
    color:transparent;
    transition:.3s;
}
.whatsapp-button:hover i {
    color:#25D366;
    font-size:44px;
    transition:.3s;
}*/
.whatsapp-button i {
    color:#fff;
    font-size:36px;
    transition:.3s;
    line-height: 66px;transition: .5s ease-in-out;
    animation: 1200ms ease 0s normal none 1 running shake;
    animation-iteration-count: infinite;
    -webkit-animation: 1200ms ease 0s normal none 1 running shake;
    -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes hoverWave {
    0% {
        box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 0 rgba(7,206,112,0.2),0 0 0 0 rgba(7,206,112,0.2)
    }
    40% {
        box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 15px rgba(7,206,112,0.2),0 0 0 0 rgba(7,206,112,0.2)
    }
    80% {
        box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 30px rgba(7,206,112,0),0 0 0 26.7px rgba(7,206,112,0.067)
    }
    100% {
        box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 30px rgba(7,206,112,0),0 0 0 40px rgba(7,206,112,0.0)
    }
}@keyframes hoverWave {
     0% {
         box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 0 rgba(7,206,112,0.2),0 0 0 0 rgba(7,206,112,0.2)
     }
     40% {
         box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 15px rgba(7,206,112,0.2),0 0 0 0 rgba(7,206,112,0.2)
     }
     80% {
         box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 30px rgba(7,206,112,0),0 0 0 26.7px rgba(7,206,112,0.067)
     }
     100% {
         box-shadow:0 8px 10px rgba(7,206,112,0.3),0 0 0 30px rgba(7,206,112,0),0 0 0 40px rgba(7,206,112,0.0)
     }
 }
@keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }
    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }
    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }
    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }
    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }
    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }
    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }
    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }
    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }
    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}
@-webkit-keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }
    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }
    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }
    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }
    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }
    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }
    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }
    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }
    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }
    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}



.whatsapp-button.top_right {
    right: 50px;
    top: 50px;
}
.whatsapp-button.top_left {
    left: 50px;
    top: 50px;
}
.whatsapp-button.top_center {
    left: 50%;
    top: 50px;
}
.whatsapp-button.center_right {
    right: 50px;
    top: 50%;
}
.whatsapp-button.center_left {
    left: 50px;
    top: 50%;
}
.whatsapp-button.bottom_right {
    right: 50px;
    bottom: 50px;
}
.whatsapp-button.bottom_left {
    left: 50px;
    bottom: 50px;
}
.whatsapp-button.bottom_center {
    left: 50%;
    bottom: 50px;
}
.dropdown-menu {
    font-size: 1.4rem !important;
}
.details-action-col__rows .product-details-quantity {
    margin-left: 0 !important;
    margin-right: 2.4rem !important;
}
.slick-next:before, [dir=rtl] .slick-prev:before {
    content: "\f113";
    font-family: LineAwesome, sans-serif;
}
.slick-prev:before {
    content: "\f110";
    font-family: LineAwesome, sans-serif;
}
.product-gallery-vertical .slick-vertical .slick-next {
    overflow: hidden !important;
}
.product-gallery-vertical .slick-vertical .slick-arrow::before {
    display   : block;
    transform : rotate(0deg) !important;
}
@media screen and (min-width: 1200px) {
    .header-8 .main-nav {
        margin-right : 10rem !important;
        flex-grow: 1;
    }
    .header-middle.sticky-header .container .header-right {
        flex-grow: 1
    }
}
.header.header-8 .header-top a {
    text-transform: none !important;
}
.header.header-8 .header-top .top-menu a {
    text-transform: uppercase !important;
}