@import url("https://fonts.googleapis.com/css?family=Open+Sans:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800");
[dir=rtl] body, [dir=rtl] a, [dir=rtl] p, [dir=rtl] .page-header h1 {
  font-family: "Open Sans", "Poppins", sans-serif !important; }

[dir=rtl] .header-intro-clearance .header-middle .header-right,
[dir=rtl] .header-intro-clearance .header-bottom .header-right {
  flex: 0 0 calc((100% + 20px) / 4);
  max-width: calc((100% + 20px) / 4);
  margin: 0;
  justify-content: flex-end; }

[dir=rtl] .header-intro-clearance .cart-dropdown,
[dir=rtl] .header-intro-clearance .wishlist,
[dir=rtl] .header-intro-clearance .account {
  padding-left: 0;
  padding-right: 3rem; }

[dir=rtl] .header-right {
  margin-left: 0;
  margin-right: auto; }

[dir=rtl] .header.header-8 .top-menu li + li {
  margin-right: 2.5rem;
  margin-left: 0; }

[dir=rtl] .cart-dropdown {
  padding-left: 0;
  padding-right: 2.5rem; }

[dir=rtl] .cart-dropdown .dropdown-menu.dropdown-menu-right,
[dir=rtl] .compare-dropdown .dropdown-menu.dropdown-menu-right,
[dir=rtl] .cross-txt {
  left: -1px;
  right: initial; }

[dir=rtl] .dropdown-cart-total .cart-total-price {
  margin-right: auto;
  margin-left: 0; }

[dir=rtl] .btn i:last-child {
  margin-left: 0;
  margin-right: 1rem; }

[dir=rtl] .btn-product:before {
  margin-left: 0;
  margin-right: .9rem; }

[dir=rtl] .header-search-extended::after {
  left: 0; }

[dir=rtl] .cart-dropdown .cart-count, [dir=rtl] .wishlist .wishlist-count {
  margin-right: -1rem;
  margin-left: initial; }

[dir=rtl] .banner-lg .intro .action a i {
  margin-left: 0;
  margin-right: 1.5rem; }

[dir=rtl] .breadcrumb-item + .breadcrumb-item:before {
  content: '\f111';
  padding-left: .7rem;
  padding-right: 0; }

[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: 1rem;
  padding-left: 0; }

[dir=rtl] .dropdown-menu, [dir=rtl] .tooltip, [dir=rtl] body, [dir=rtl] caption {
  text-align: initial; }

[dir=rtl] .details-action-wrapper .btn-product:before {
  margin-left: 1rem;
  margin-right: 0; }

[dir=rtl] .header-intro-clearance .cart-dropdown .cart-count {
  left: -.3rem;
  right: initial; }

[dir=rtl] .icon-shopping-cart:before {
  transform: rotateY(180deg); }

[dir=rtl] .product-details .product-title {
  padding-right: revert;
  padding-left: 1rem; }

[dir=rtl] .header-bottom .menu > li > .sf-with-ul, [dir=rtl] .menu-vertical.sf-arrows > li > .sf-with-ul {
  padding-left: 3rem;
  padding-right: revert; }

[dir=rtl] .header-intro-clearance .header-bottom .menu.sf-arrows > li > .sf-with-ul::after {
  left: 1.3rem;
  right: revert; }

[dir=rtl] .toolbox-sort label {
  margin-left: 1.6rem;
  margin-right: revert; }

[dir=rtl] .sidebar-toggler i {
  margin-left: .9rem;
  margin-right: revert; }

[dir=rtl] .sidebar-toggler {
  margin-left: 3rem;
  margin-right: revert; }

[dir=rtl] .toolbox .select-custom::after {
  left: .9rem;
  right: revert; }

[dir=rtl] .page-link.page-link-next i {
  margin-right: 1rem;
  margin-left: 0; }

[dir=rtl] .product.product-11 .btn-product, [dir=rtl] .social-icons-color .social-icon:not(:last-child), [dir=rtl] .social-icons-simple .social-icon:not(:last-child), [dir=rtl] .social-label {
  margin-left: 2rem;
  margin-right: revert; }

[dir=rtl] .footer-bottom .social-icons {
  margin-left: 0;
  margin-right: auto; }

[dir=rtl] #scroll-top {
  right: revert;
  left: 50px; }

[dir=rtl] .header-right p {
  margin-right: 1rem;
  margin-left: revert; }

[dir=rtl] .menu.sf-arrows .menu-col {
  padding: 15rem 4.5rem 3rem 0; }

[dir=rtl] .widget-collapsible .widget-title a {
  padding-left: 3rem;
  padding-right: revert; }

[dir=rtl] .widget-collapsible .widget-title a:after {
  left: 1.6rem;
  right: revert; }

[dir=rtl] .widget-clean a {
  margin-right: auto;
  margin-left: revert; }

[dir=rtl] .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: revert; }

[dir=rtl] .details-action-wrapper .btn-product {
  padding-inline-start: 10px; }

[dir=rtl] .top-menu:not(.top-link-menu) ul {
  right: auto;
  left: 0; }

[dir=rtl] .header .container, [dir=rtl] .header .container-fluid {
  justify-content: space-between; }

[dir=rtl] .pr-4, [dir=rtl] .px-4 {
  padding-inline-end: 1.5rem !important; }

@media screen and (max-width: 1800px) {
  [dir=rtl] .header.sidebar {
    padding: 0 2rem 0 0; }
  [dir=rtl] .header.sidebar .menu .megamenu {
    left: initial;
    right: calc(100% / 6 + 10px); }
  [dir=rtl] .menu.sf-arrows .sf-with-ul::after {
    right: initial;
    left: 0; }
  [dir=rtl] .icon-long-arrow-right:before, [dir=rtl] .menu.sf-arrows .sf-with-ul::after {
    content: '\f273'; }
  [dir=rtl] .sf-with-ul {
    text-align: right; } }

[dir=rtl] .footer-middle .row {
  flex-direction: row-reverse; }

@media screen and (min-width: 1200px) {
  [dir=rtl] .intro-content {
    left: auto;
    right: 100px; } }
