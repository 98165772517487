/*Product page*/
/*Vertical gallery*/
.product-gallery-vertical {}
.product-gallery-vertical .slick-vertical {
    height: 457px;
    overflow: hidden;
}
.product-gallery-vertical .slick-vertical .slick-arrow {
    width: 107px;
    z-index: 100;
    left: auto;
    right: auto;
}
.product-gallery-vertical .slick-vertical .slick-arrow::before {
    display: block;
    transform: rotate(-90deg);
}
.product-gallery-vertical .slick-vertical .slick-next {
    top: 10px;
}
.product-gallery-vertical .slick-vertical .slick-prev {
    top: auto;
    bottom: -10px;
}