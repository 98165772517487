/* Skin demo 10 - index-10.html */
/* Skin structure */
::-moz-selection {
  background-color: var(--primary-color, #445f84); }

::selection {
  background-color: var(--primary-color, #445f84); }

.bg-primary {
  background-color: var(--primary-color, #445f84) !important; }

.bg-secondary {
  background-color: var(--secondary-color, #55739d) !important; }

a {
  color: #445f84; }

a:hover,
a:focus {
  /*color: darken($link-color, 10%);*/
  color: var(--primary-color-darken, #445f84); }

.widget-about-info a, .widget-clean a {
  color: var(--primary-color, #445f84) !important; }

.widget-about-info a:hover, .widget-clean a:hover {
  color: var(--secondary-color, #55739d) !important; }

.link-underline {
  box-shadow: 0 1px 0 0 var(--primary-color, #445f84); }

.text-primary {
  color: var(--primary-color, #445f84) !important; }

.text-secondary {
  color: var(--secondary-color, #55739d) !important; }

/* Elements */
.alert-primary {
  background-color: var(--primary-color, #445f84); }

.banner-badge .banner-link:hover .banner-link-text,
.banner-badge .banner-link:focus .banner-link-text {
  background-color: var(--primary-color, #445f84); }

.header-3 .header-search-extended .btn,
.header-4 .header-search-extended .btn {
  background-color: transparent;
  color: #333; }
  .header-3 .header-search-extended .btn:hover, .header-3 .header-search-extended .btn:focus,
  .header-4 .header-search-extended .btn:hover,
  .header-4 .header-search-extended .btn:focus {
    color: var(--primary-color, #445f84); }

.btn-video {
  color: var(--primary-color, #445f84); }
  .btn-video:hover, .btn-video:focus {
    background-color: var(--secondary-color, #55739d); }

.breadcrumb-item a:hover, .breadcrumb-item a:focus {
  color: var(--primary-color, #445f84); }

.btn-link {
  color: var(--primary-color, #445f84); }
  .btn-link:hover, .btn-link:focus,
  .btn-link .btn-link-dark:hover,
  .btn-link .btn-link-dark:focus {
    color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84); }

/* generated with mixins */
.btn-primary {
  color: #fff;
  background-color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84);
  box-shadow: none; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #55739d);
    border-color: var(--secondary-color, #55739d);
    box-shadow: none; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84); }

.btn-secondary {
  color: #fff;
  background-color: var(--primary-color, #55739d);
  border-color: var(--primary-color, #55739d);
  box-shadow: none; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #445f84);
    border-color: var(--secondary-color, #445f84);
    box-shadow: none; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: var(--primary-color, #55739d);
    border-color: var(--primary-color, #55739d); }

.btn-white {
  color: #333333;
  background-color: var(--primary-color, #fff);
  border-color: var(--primary-color, #fff);
  box-shadow: none; }
  .btn-white:hover, .btn-white:focus, .btn-white.focus, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #445f84);
    border-color: var(--secondary-color, #445f84);
    box-shadow: none; }
  .btn-white.disabled, .btn-white:disabled {
    color: #333333;
    background-color: var(--primary-color, #fff);
    border-color: var(--primary-color, #fff); }

.btn-white-2 {
  color: #333333;
  background-color: var(--primary-color, #fff);
  border-color: var(--primary-color, #fff);
  box-shadow: none; }
  .btn-white-2:hover, .btn-white-2:focus, .btn-white-2.focus, .btn-white-2:not(:disabled):not(.disabled):active, .btn-white-2:not(:disabled):not(.disabled).active,
  .show > .btn-white-2.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #55739d);
    border-color: var(--secondary-color, #55739d);
    box-shadow: none; }
  .btn-white-2.disabled, .btn-white-2:disabled {
    color: #333333;
    background-color: var(--primary-color, #fff);
    border-color: var(--primary-color, #fff); }

.btn-primary-white {
  color: #fff;
  background-color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84);
  box-shadow: none; }
  .btn-primary-white:hover, .btn-primary-white:focus, .btn-primary-white.focus, .btn-primary-white:not(:disabled):not(.disabled):active, .btn-primary-white:not(:disabled):not(.disabled).active,
  .show > .btn-primary-white.dropdown-toggle {
    color: #445f84;
    background-color: var(--secondary-color, #fff);
    border-color: var(--secondary-color, #fff);
    box-shadow: none; }
  .btn-primary-white.disabled, .btn-primary-white:disabled {
    color: #fff;
    background-color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84); }

.btn-white-primary {
  color: #445f84;
  background-color: var(--primary-color, #fff);
  border-color: var(--primary-color, #fff);
  box-shadow: none; }
  .btn-white-primary:hover, .btn-white-primary:focus, .btn-white-primary.focus, .btn-white-primary:not(:disabled):not(.disabled):active, .btn-white-primary:not(:disabled):not(.disabled).active,
  .show > .btn-white-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #445f84);
    border-color: var(--secondary-color, #445f84);
    box-shadow: none; }
  .btn-white-primary.disabled, .btn-white-primary:disabled {
    color: #445f84;
    background-color: var(--primary-color, #fff);
    border-color: var(--primary-color, #fff); }

.btn-dark {
  color: #fff;
  background-color: var(--primary-color, #333);
  border-color: var(--primary-color, #333);
  box-shadow: none; }
  .btn-dark:hover, .btn-dark:focus, .btn-dark.focus, .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #445f84);
    border-color: var(--secondary-color, #445f84);
    box-shadow: none; }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: var(--primary-color, #333);
    border-color: var(--primary-color, #333); }

.btn-outline {
  /*@include button-outline-variant(
        $primary-color, // color
        $primary-color, // color-hover
        transparent,    // active bg
        #ebebeb,        // border color
        #ebebeb,        // active border
        0 5px 10px rgba(0, 0, 0, .05), // box shadow
        0 5px 10px rgba(0, 0, 0, .15)  // box shadow hover
    );*/
  /*color: var(--primary-color, $primary-color);
    background-color: transparent;
    background-image: none;
    border-color: #ebebeb;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
        border-color: #ebebeb;
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
    }*/
  color: var(--primary-color, #445f84);
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  .btn-outline:hover, .btn-outline:focus, .btn-outline.focus, .btn-outline:not(:disabled):not(.disabled):active, .btn-outline:not(:disabled):not(.disabled).active,
  .show > .btn-outline.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15); }
  .btn-outline.disabled, .btn-outline:disabled {
    color: var(--primary-color, #445f84);
    background-color: transparent; }

.btn-outline-primary {
  /*@include button-outline-variant(
        $primary-color, // $color
        $primary-color, // $color-hover
        transparent,    // $active-background
        $primary-color, // $border-color
        #ebebeb,        // $active-border
        none,           // $box-shadow
        0 5px 10px rgba(0, 0, 0, .05) //$box-shadow-hover
    );*/
  /*color: var(--primary-color, $primary-color);
    background-color: transparent;
    background-image: none;
    border-color: var(--primary-color, $primary-color);
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
        border-color: #ebebeb;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    }

    &.disabled,
    &:disabled {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
    }*/
  color: var(--primary-color, #445f84);
  background-color: transparent;
  background-image: none;
  border-color: var(--primary-color, #445f84);
  box-shadow: none; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: var(--primary-color, #445f84);
    background-color: transparent; }

.btn-outline-primary-2 {
  /*@include button-outline-variant(
        $primary-color, // $color
        #fff,           // $color-hover
        $primary-color, // $active-background
        $primary-color, // $border-color
        $primary-color, // $active-border
        none,           // $box-shadow
        none            // $box-shadow-hover
    );*/
  /*color: var(--primary-color, $primary-color);
    background-color: transparent;
    background-image: none;
    border-color: var(--primary-color, $primary-color);
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: #fff;
        background-color: var(--primary-color, $primary-color);
        border-color: var(--primary-color, $primary-color);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
    }*/
  color: var(--primary-color, #445f84);
  background-color: transparent;
  background-image: none;
  border-color: var(--primary-color, #445f84);
  box-shadow: none; }
  .btn-outline-primary-2:hover, .btn-outline-primary-2:focus, .btn-outline-primary-2.focus, .btn-outline-primary-2:not(:disabled):not(.disabled):active, .btn-outline-primary-2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-2.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84);
    box-shadow: none; }
  .btn-outline-primary-2.disabled, .btn-outline-primary-2:disabled {
    color: var(--primary-color, #445f84);
    background-color: transparent; }

.btn-outline-light {
  /*@include button-outline-variant(
        #fff,           // $color
        $primary-color, // $color-hover
        transparent,    // $active-background
        #fff,           // $border-color
        #fff,           // $active-border
    );*/
  /*color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: #fff;
        background-color: transparent;
    }*/
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none; }
  .btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light.focus, .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: transparent;
    border-color: #fff;
    box-shadow: none; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fff;
    background-color: transparent; }

.btn-outline-dark {
  /*@include button-outline-variant(
        #333333,        // $color
        $primary-color, // $color-hover
        transparent,    // $active-background
        #d7d7d7,        // $border-color
        #ebebeb,        // $active-border
        none,           // $box-shadow
        0 5px 10px rgba(0, 0, 0, .05) // $box-shadow-hover
    );*/
  /*color: #333333;
    background-color: transparent;
    background-image: none;
    border-color: #d7d7d7;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
        border-color: #ebebeb;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
    }

    &.disabled,
    &:disabled {
        color: #333333;
        background-color: transparent;
    }*/
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none; }
  .btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark.focus, .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: transparent;
    border-color: #ebebeb;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-dark-2 {
  /*@include button-outline-variant(
        #333333,        // $color
        $primary-color, // $color-hover
        #fafafa,        // $active-background
        #ebebeb,        // $border-color
        #ebebeb,        // $active-border
        none,           // $box-shadow
        none            // $box-shadow-hover
    );*/
  /*color: #333333;
    background-color: transparent;
    background-image: none;
    border-color: #ebebeb;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: var(--primary-color, $primary-color);
        background-color: #fafafa;
        border-color: #ebebeb;
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: #333333;
        background-color: transparent;
    }*/
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: none; }
  .btn-outline-dark-2:hover, .btn-outline-dark-2:focus, .btn-outline-dark-2.focus, .btn-outline-dark-2:not(:disabled):not(.disabled):active, .btn-outline-dark-2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-2.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: #fafafa;
    border-color: #ebebeb;
    box-shadow: none; }
  .btn-outline-dark-2.disabled, .btn-outline-dark-2:disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-dark-3 {
  /*@include button-outline-variant(
        #333333,        // $color
        $primary-color, // $color-hover
        transparent,    // $active-background
        #d7d7d7,        // $border-color
        $primary-color, // $active-border
        none,           // $box-shadow
        none            // $box-shadow-hover
    );*/
  /*color: #333333;
    background-color: transparent;
    background-image: none;
    border-color: #d7d7d7;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: var(--primary-color, $primary-color);
        background-color: transparent;
        border-color: var(--primary-color, $primary-color);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: #333333;
        background-color: transparent;
    }*/
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none; }
  .btn-outline-dark-3:hover, .btn-outline-dark-3:focus, .btn-outline-dark-3.focus, .btn-outline-dark-3:not(:disabled):not(.disabled):active, .btn-outline-dark-3:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-3.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: transparent;
    border-color: var(--primary-color, #445f84);
    box-shadow: none; }
  .btn-outline-dark-3.disabled, .btn-outline-dark-3:disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-darker {
  /*@include button-outline-variant(
        #333333,        // $color
        #fff,           // $color-hover
        $primary-color, // $active-background
        #d7d7d7,        // $border-color
        $primary-color, // $active-border
        none,           // $box-shadow
        none            // $box-shadow-hover
    );*/
  /*color: #333333;
    background-color: transparent;
    background-image: none;
    border-color: #d7d7d7;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: #fff;
        background-color: var(--primary-color, $primary-color);
        border-color: var(--primary-color, $primary-color);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: #333333;
        background-color: transparent;
    }*/
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none; }
  .btn-outline-darker:hover, .btn-outline-darker:focus, .btn-outline-darker.focus, .btn-outline-darker:not(:disabled):not(.disabled):active, .btn-outline-darker:not(:disabled):not(.disabled).active,
  .show > .btn-outline-darker.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84);
    box-shadow: none; }
  .btn-outline-darker.disabled, .btn-outline-darker:disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-gray {
  /*@include button-outline-variant(
        #333333,        // $color
        #fff,           // $color-hover
        $primary-color, // $active-background
        #959595,        // $border-color
        $primary-color, // $active-border
        none,           // $box-shadow
        none            // $box-shadow-hover
    );*/
  /*color: #333333;
    background-color: transparent;
    background-image: none;
    border-color: #959595;
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle  {
        color: #fff;
        background-color: var(--primary-color, $primary-color);
        border-color: var(--primary-color, $primary-color);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: #333333;
        background-color: transparent;
    }*/
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #959595;
  box-shadow: none; }
  .btn-outline-gray:hover, .btn-outline-gray:focus, .btn-outline-gray.focus, .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84);
    box-shadow: none; }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-lightgray {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: none; }
  .btn-outline-lightgray:hover, .btn-outline-lightgray:focus, .btn-outline-lightgray.focus, .btn-outline-lightgray:not(:disabled):not(.disabled):active, .btn-outline-lightgray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lightgray.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: #f5f6f9;
    border-color: #ebebeb;
    box-shadow: none; }
  .btn-outline-lightgray.disabled, .btn-outline-lightgray:disabled {
    color: #333333;
    background-color: transparent; }

.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none; }
  .btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white.focus, .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-color, #445f84);
    border-color: var(--primary-color, #445f84);
    box-shadow: none; }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }

.btn-outline-white-2 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none; }
  .btn-outline-white-2:hover, .btn-outline-white-2:focus, .btn-outline-white-2.focus, .btn-outline-white-2:not(:disabled):not(.disabled):active, .btn-outline-white-2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white-2.dropdown-toggle {
    color: #fff;
    background-color: var(--secondary-color, #55739d);
    border-color: var(--secondary-color, #55739d);
    box-shadow: none; }
  .btn-outline-white-2.disabled, .btn-outline-white-2:disabled {
    color: #fff;
    background-color: transparent; }

.btn-outline-white-4 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none; }
  .btn-outline-white-4:hover, .btn-outline-white-4:focus, .btn-outline-white-4.focus, .btn-outline-white-4:not(:disabled):not(.disabled):active, .btn-outline-white-4:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white-4.dropdown-toggle {
    color: var(--primary-color, #445f84);
    background-color: #fff;
    border-color: #fff;
    box-shadow: none; }
  .btn-outline-white-4.disabled, .btn-outline-white-4:disabled {
    color: #fff;
    background-color: transparent; }

.newsletter-popup-container .input-group .btn:hover, .newsletter-popup-container .input-group .btn:focus {
  background-color: #445f84; }

.bg-image .btn-link-dark:hover, .bg-image .btn-link-dark:focus {
  color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84); }

.bg-image .btn-outline-primary:hover, .bg-image .btn-outline-primary:focus, .bg-image .btn-outline-primary.focus, .bg-image .btn-outline-primary:not(:disabled):not(.disabled):active, .bg-image .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .bg-image .btn-outline-primary.dropdown-toggle {
  background-color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84); }

.bg-image .btn-outline-dark:hover, .bg-image .btn-outline-dark:focus, .bg-image .btn-outline-dark.focus, .bg-image .btn-outline-dark:not(:disabled):not(.disabled):active, .bg-image .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .bg-image .btn-outline-dark.dropdown-toggle {
  color: var(--primary-color, #445f84); }

.card-title a {
  color: var(--primary-color, #445f84); }
  .card-title a:before {
    color: var(--primary-color, #445f84); }
  .card-title a.collapsed:hover, .card-title a.collapsed:focus {
    color: var(--primary-color, #445f84); }

.count-wrapper {
  color: var(--primary-color, #445f84); }

.feature-box i {
  color: var(--primary-color, #445f84); }

.feature-box-simple i {
  color: var(--primary-color, #445f84); }

.form-control:focus {
  border-color: var(--primary-color, #445f84); }

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--primary-color, #445f84); }

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--primary-color, #445f84); }

.icon-box-icon {
  color: var(--primary-color, #445f84); }

.icon-box-circle .icon-box-icon {
  background-color: var(--primary-color, #445f84); }

.instagram-feed-content a:hover, .instagram-feed-content a:focus {
  color: var(--primary-color, #445f84); }

.close:hover, .close:focus {
  color: var(--primary-color, #445f84); }

.page-header h1 {
  color: var(--primary-color, #445f84); }
  .page-header h1 span {
    color: var(--header-span-color, #2f3946); }

.page-link:hover, .page-link:focus {
  color: var(--primary-color, #445f84); }

.page-item.active .page-link {
  color: var(--primary-color, #445f84); }

.social-icon:hover, .social-icon:focus {
  color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84); }

.testimonial-icon:before {
  color: var(--primary-color, #445f84); }

.nav.nav-tabs .nav-link:hover, .nav.nav-tabs .nav-link:focus {
  color: var(--primary-color, #445f84); }

.nav.nav-tabs .nav-item.show .nav-link,
.nav.nav-tabs .nav-item .nav-link.active {
  color: var(--primary-color, #445f84); }

.nav.nav-pills .nav-link:hover, .nav.nav-pills .nav-link:focus {
  color: var(--primary-color, #445f84); }

.nav.nav-pills .nav-item.show .nav-link,
.nav.nav-pills .nav-item .nav-link.active {
  color: var(--primary-color, #445f84);
  border-bottom-color: var(--primary-color, #445f84); }

.nav.nav-border-anim .nav-link:before {
  background-color: var(--primary-color, #445f84); }

.title-link:hover, .title-link:focus {
  box-shadow: 0 1px 0 0 var(--primary-color, #445f84); }

.product-countdown.countdown-primary .countdown-amount {
  color: var(--primary-color, #445f84); }

.product-title a:hover, .product-title a:focus {
  color: var(--primary-color, #445f84); }

.product-price {
  color: var(--primary-color, #445f84); }
  .product-price .new-price {
    color: var(--primary-color, #445f84); }

.product-label.label-primary {
  background-color: var(--primary-color, #445f84); }

.product-label.label-secondary {
  background-color: var(--secondary-color, #55739d); }

.product-label-text {
  color: var(--primary-color, #445f84); }

.ratings-primary .ratings-val {
  color: var(--primary-color, #445f84); }

.ratings-text a:hover, .ratings-text a:focus {
  color: var(--primary-color, #445f84); }

.btn-product {
  color: var(--primary-color, #445f84); }
  .btn-product:hover span, .btn-product:focus span {
    color: var(--primary-color, #445f84);
    box-shadow: 0 1px 0 0 var(--primary-color, #445f84); }

.btn-product-icon {
  color: var(--primary-color, #445f84); }
  .btn-product-icon:hover, .btn-product-icon:focus {
    background-color: var(--primary-color, #445f84); }

.product-body .btn-wishlist:hover, .product-body .btn-wishlist:focus {
  color: var(--primary-color, #445f84); }

.btn-expandable span {
  background-color: var(--primary-color, #445f84); }

.product.product-4 .btn-product:hover, .product.product-4 .btn-product:focus {
  background-color: var(--secondary-color, #55739d); }

.product.product-5 .btn-product {
  color: var(--primary-color, #445f84); }
  .product.product-5 .btn-product:hover, .product.product-5 .btn-product:focus {
    background-color: var(--primary-color, #445f84); }

.product.product-7 .btn-product {
  color: var(--primary-color, #445f84); }
  .product.product-7 .btn-product span {
    color: var(--primary-color, #445f84); }
  .product.product-7 .btn-product:hover, .product.product-7 .btn-product:focus {
    background-color: var(--primary-color, #445f84);
    border-bottom-color: var(--primary-color, #445f84); }

.product.product-8 .new-price {
  color: var(--primary-color, #445f84); }

.product.product-8 .btn-product:before {
  color: var(--primary-color, #445f84); }

.product.product-8 .btn-product:hover, .product.product-8 .btn-product:focus {
  background-color: var(--primary-color, #445f84); }

.product.product-list .btn-product:hover, .product.product-list .btn-product:focus {
  color: var(--primary-color, #445f84); }

.product.product-list .btn-product.btn-cart {
  color: var(--primary-color, #445f84);
  border-bolor: var(--primary-color, #445f84); }
  .product.product-list .btn-product.btn-cart:hover, .product.product-list .btn-product.btn-cart:focus {
    background-color: var(--primary-color, #445f84); }

/* Footers */
.footer a:hover,
.footer a:focus {
  color: var(--primary-color, #445f84); }

.footer-dark.footer-2 .widget-about-title {
  color: var(--primary-color, #445f84); }

/* Headers */
.header-top a:hover, .header-top a:focus {
  color: var(--primary-color, #445f84); }

.top-menu span {
  color: var(--primary-color, #445f84); }

.header-menu a:hover, .header-menu a:focus {
  color: var(--primary-color, #445f84); }

.account a:hover, .account a:focus {
  color: var(--primary-color, #445f84); }

.wishlist a:hover, .wishlist a:focus {
  color: var(--primary-color, #445f84); }

.wishlist a .wishlist-count {
  background-color: var(--primary-color, #445f84); }

.cart-dropdown:hover .dropdown-toggle, .cart-dropdown.show .dropdown-toggle,
.compare-dropdown:hover .dropdown-toggle,
.compare-dropdown.show .dropdown-toggle {
  color: var(--primary-color, #445f84); }

.compare-product-title a:hover, .compare-product-title a:focus {
  color: var(--primary-color, #445f84); }

.compare-actions .action-link:hover, .compare-actions .action-link:focus {
  color: var(--primary-color, #445f84); }

.cart-dropdown .cart-count {
  background-color: var(--primary-color, #445f84); }

.cart-dropdown .product-title a:hover, .cart-dropdown .product-title a:focus {
  color: var(--primary-color, #445f84); }

.cart-dropdown .dropdown-cart-action .btn-primary {
  border-color: var(--primary-color, #445f84); }
  .cart-dropdown .dropdown-cart-action .btn-primary:hover, .cart-dropdown .dropdown-cart-action .btn-primary:focus {
    border-color: var(--secondary-color, #55739d); }

.wishlist-link .wishlist-count {
  background-color: var(--primary-color, #445f84); }

.wishlist-link:hover, .wishlist-link:focus {
  color: var(--primary-color, #445f84); }

.search-toggle:hover,
.search-toggle:focus,
.search-toggle.active {
  color: var(--primary-color, #445f84); }

/* Menu */
.menu li:hover > a,
.menu li.show > a,
.menu li.active > a {
  color: var(--primary-color, #445f84); }

.demo-item a:hover, .demo-item a:focus {
  color: var(--primary-color, #445f84); }

.tip {
  background-color: var(--primary-color, #445f84); }

.header-bottom .menu > li > a:before {
  background-color: var(--primary-color, #445f84); }

.header-bottom .menu > li > a:hover,
.header-bottom .menu > li > a:focus {
  color: var(--primary-color, #445f84) !important; }
  .header-bottom .menu > li > a:hover:before,
  .header-bottom .menu > li > a:focus:before {
    background-color: var(--primary-color, #445f84) !important; }

.header-bottom .menu > li.show > a {
  color: var(--primary-color, #445f84) !important; }
  .header-bottom .menu > li.show > a:before {
    background-color: var(--primary-color, #445f84) !important; }

.category-dropdown .dropdown-toggle:before {
  background-color: var(--primary-color, #445f84); }

.category-dropdown .dropdown-toggle:hover, .category-dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: var(--primary-color, #445f84); }

.category-dropdown:not(.is-on):hover .dropdown-toggle {
  background-color: var(--primary-color, #445f84); }

.category-dropdown.show .dropdown-toggle {
  color: #fff;
  background-color: var(--primary-color, #445f84); }

.category-dropdown .dropdown-item:hover, .category-dropdown .dropdown-item:focus {
  color: var(--primary-color, #445f84); }

/* Vertical Menu */
.menu-vertical li:hover > a,
.menu-vertical li.show > a,
.menu-vertical li.active > a {
  color: var(--primary-color, #445f84); }

.menu-vertical > li:hover > a,
.menu-vertical > li.show > a,
.menu-vertical > li.active > a {
  color: var(--primary-color, #445f84); }

.mobile-menu-close:hover, .mobile-menu-close:focus {
  color: var(--primary-color, #445f84); }

.mobile-menu li a:hover, .mobile-menu li a:focus {
  color: var(--primary-color, #445f84); }

.mobile-menu li.open > a, .mobile-menu li.active > a {
  color: var(--primary-color, #445f84); }

.mmenu-btn:hover, .mmenu-btn:focus {
  color: var(--primary-color, #445f84); }

.mobile-search .form-control:focus {
  border-color: var(--primary-color, #445f84); }

.nav.nav-pills-mobile .nav-link.active, .nav.nav-pills-mobile .nav-link:hover, .nav.nav-pills-mobile .nav-link:focus {
  color: var(--primary-color, #445f84);
  border-bottom-color: var(--primary-color, #445f84); }

.mobile-cats-menu li a:hover, .mobile-cats-menu li a:focus {
  color: var(--primary-color, #445f84); }

.mobile-menu-light .mobile-menu li.open > a, .mobile-menu-light .mobile-menu li.active > a {
  color: var(--primary-color, #445f84); }

.mobile-menu-light .mobile-search .form-control:focus {
  border-color: var(--primary-color, #445f84); }

/* Header 4 */
.header-4 .header-search .header-search-wrapper {
  border-color: var(--primary-color, #445f84); }

.header-4 .dropdown.category-dropdown .dropdown-toggle:not(:hover):not(:focus) {
  color: var(--primary-color, #445f84); }

@media screen and (max-width: 991px) {
  .header-4 .header-search-visible .header-search-wrapper:before {
    border-bottom-color: var(--primary-color, #445f84); } }

/* Header 6 */
.header-6 .header-middle a:hover, .header-6 .header-middle a:focus {
  color: var(--primary-color, #445f84); }

.header-8 .header-top {
  /*color: lighten($primary-color, 31%);*/
  color: var(--primary-color-lighten, var(--primary-color, #445f84));
  background-color: var(--primary-color, #445f84); }

/* Header 10 */
.header-10 .header-search .header-search-wrapper {
  border-color: var(--primary-color, #445f84); }

.header-10 .category-dropdown .dropdown-toggle {
  background-color: var(--primary-color, #445f84); }

.header-10 .menu-vertical .menu-title {
  color: var(--primary-color, #445f84); }

/* Header 12 */
.header-12 .dropdown.category-dropdown .dropdown-toggle {
  background-color: var(--primary-color, #445f84); }

/* Header 13 */
.header-13 .dropdown.category-dropdown .dropdown-toggle {
  background-color: var(--primary-color, #445f84); }

/* Header 14 */
.header-14 .header-search .header-search-wrapper {
  border-color: var(--primary-color, #445f84); }

.header-14 .dropdown.category-dropdown .dropdown-toggle {
  background-color: var(--primary-color, #445f84); }

/* Pages */
.entry-video a:hover:after, .entry-video a:focus:after {
  color: var(--primary-color, #445f84); }

.entry-meta a:hover, .entry-meta a:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.entry-title a:hover, .entry-title a:focus {
  color: var(--primary-color, #445f84); }

.entry-cats a:hover, .entry-cats a:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.read-more:hover, .read-more:focus {
  box-shadow: 0 1px 0 0 var(--primary-color, #445f84); }

.menu-cat a:hover, .menu-cat a:focus {
  color: var(--primary-color, #445f84); }

.menu-cat li.active a {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.widget-search .btn:hover, .widget-search .btn:focus {
  color: var(--primary-color, #445f84); }

.widget-cats a:hover, .widget-cats a:focus {
  color: var(--primary-color, #445f84); }

.posts-list a:hover, .posts-list a:focus {
  color: var(--primary-color, #445f84); }

.tagcloud a:hover, .tagcloud a:focus {
  color: var(--primary-color, #445f84); }

.table .total-col {
  color: var(--primary-color, #445f84); }

.btn.btn-spinner:hover, .btn.btn-spinner:focus {
  color: var(--primary-color, #445f84); }

.table.table-summary .summary-shipping-estimate a:hover, .table.table-summary .summary-shipping-estimate a:focus {
  color: var(--primary-color, #445f84);
  border-bottom-color: var(--primary-color, #445f84); }

.sidebar-toggler:hover, .sidebar-toggler:focus {
  color: var(--primary-color, #445f84); }

#filter-price-range {
  color: var(--primary-color, #445f84); }

.checkout-discount label span {
  color: var(--primary-color, #445f84); }

.checkout-discount .form-control:focus {
  border-color: var(--primary-color, #445f84); }

.table.table-summary a:hover, .table.table-summary a:focus {
  color: var(--primary-color, #445f84); }

.table.table-summary .summary-total td {
  color: var(--primary-color, #445f84); }

.accordion-summary .card-title a:before {
  border-color: var(--primary-color, #445f84); }

.accordion-summary .card-title a:after {
  background-color: var(--primary-color, #445f84); }

.paypal-link:hover, .paypal-link:focus {
  color: var(--primary-color, #445f84) !important; }

.coming-countdown .countdown-amount {
  color: var(--primary-color, #445f84); }

.coming-countdown.countdown-separator .countdown-section:not(:last-child):after {
  color: var(--primary-color, #445f84); }

.contact-box a:hover, .contact-box a:focus {
  color: var(--primary-color, #445f84); }

.contact-list a:hover, .contact-list a:focus {
  color: var(--primary-color, #445f84); }

.contact-list i {
  color: var(--primary-color, #445f84); }

.store a:not(.btn):hover, .store a:not(.btn):focus {
  color: var(--primary-color, #445f84); }

.nav-dashboard .nav-link:hover, .nav-dashboard .nav-link:focus, .nav-dashboard .nav-link.active {
  color: var(--primary-color, #445f84); }

.form-tab .form-footer a:hover, .form-tab .form-footer a:focus {
  color: var(--primary-color, #445f84); }

.nav-filter a:hover, .nav-filter a:focus {
  color: var(--primary-color, #445f84); }

.nav-filter .active a {
  color: var(--primary-color, #445f84);
  border-bottom-color: var(--primary-color, #445f84); }

.portfolio-title a:hover, .portfolio-title a:focus {
  color: var(--primary-color, #445f84); }

.portfolio-tags a {
  color: var(--primary-color, #445f84); }
  .portfolio-tags a:hover, .portfolio-tags a:focus {
    color: var(--primary-color, #445f84);
    box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.btn-product-gallery,
.slick-arrow {
  background-color: var(--primary-color, #445f84); }
  .btn-product-gallery:hover, .btn-product-gallery:focus,
  .slick-arrow:hover,
  .slick-arrow:focus {
    background-color: var(--secondary-color, #55739d) !important; }

.product-gallery-item:before {
  border-color: var(--primary-color, #445f84); }

.product-pager-link:hover, .product-pager-link:focus {
  color: var(--primary-color, #445f84); }
  .product-pager-link:hover span, .product-pager-link:focus span {
    box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.product-details .product-cat a:hover, .product-details .product-cat a:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.product-details .product-size a.active, .product-details .product-size a:hover, .product-details .product-size a:focus {
  color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84); }

.size-guide:hover, .size-guide:focus {
  color: var(--primary-color, #445f84); }

.product-details-action .btn-cart {
  color: var(--primary-color, #445f84);
  border-color: var(--primary-color, #445f84); }
  .product-details-action .btn-cart:hover, .product-details-action .btn-cart:focus {
    border-color: var(--primary-color, #445f84);
    background-color: var(--primary-color, #445f84); }

.product-details-tab .nav.nav-pills .nav-link:hover, .product-details-tab .nav.nav-pills .nav-link:focus {
  color: var(--primary-color, #445f84);
  border-bottom-color: var(--primary-color, #445f84); }

.product-desc-content a:hover, .product-desc-content a:focus {
  color: var(--primary-color, #445f84);
  border-bottom-color: var(--primary-color, #445f84); }

.product-desc-content table.table-product-additional-content h2, .product-desc-content table.table-product-additional-content h3 {
  margin-bottom: 0.5rem; }

.product-desc-content table.table-product-additional-content td {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 0px; }

.review h4 a:hover, .review h4 a:focus {
  color: var(--primary-color, #445f84); }

.review-action a:hover, .review-action a:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.product-details-extended .nav.nav-pills .nav-link.active, .product-details-extended .nav.nav-pills .nav-link:hover, .product-details-extended .nav.nav-pills .nav-link:focus {
  border-color: var(--primary-color, #445f84); }

.editor-content a:hover, .editor-content a:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.editor-content blockquote {
  border-left-color: var(--primary-color, #445f84); }

.entry-tags a:hover, .entry-tags a:focus {
  color: var(--primary-color, #445f84); }

.entry-author-details h4 a:hover, .entry-author-details h4 a:focus {
  color: var(--primary-color, #445f84); }

.author-link:hover, .author-link:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.pager-link {
  color: var(--primary-color, #445f84); }
  .pager-link:hover, .pager-link:focus {
    color: var(--primary-color, #445f84); }
    .pager-link:hover:after, .pager-link:focus:after {
      color: var(--primary-color, #445f84); }

.comment-reply:hover, .comment-reply:focus {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 var(--primary-color, #445f84); }

.comment-user h4 a:hover, .comment-user h4 a:focus {
  color: var(--primary-color, #445f84); }

.product-col .product-title a:hover, .product-col .product-title a:focus {
  color: var(--primary-color, #445f84); }

/* Plugins */
.owl-theme .owl-nav [class*='owl-'] {
  color: var(--primary-color, #445f84); }
  .owl-theme .owl-nav [class*='owl-']:not(.disabled):hover {
    border-color: var(--primary-color, #445f84);
    background: var(--primary-color, #445f84); }

.owl-theme.owl-light .owl-nav [class*='owl-']:not(.disabled):hover {
  border-color: var(--primary-color, #445f84); }

.owl-theme.owl-light .owl-dots .owl-dot:hover span {
  border-color: var(--primary-color, #445f84);
  background: var(--primary-color, #445f84); }

.owl-theme.owl-light .owl-dots .owl-dot.active span {
  border-color: var(--primary-color, #445f84);
  background: var(--primary-color, #445f84); }

.owl-full .owl-nav [class*='owl-'] {
  color: var(--primary-color, #445f84); }
  .owl-full .owl-nav [class*='owl-']:hover, .owl-full .owl-nav [class*='owl-']:focus {
    color: var(--primary-color, #445f84); }

.owl-full .owl-dots .owl-dot span {
  border-color: var(--primary-color, #445f84); }

.owl-full .owl-dots .owl-dot:hover span {
  border-color: var(--primary-color, #445f84);
  background: var(--primary-color, #445f84); }

.owl-full .owl-dots .owl-dot.active span {
  border-color: var(--primary-color, #445f84);
  background: var(--primary-color, #445f84); }

.owl-full.owl-nav-dark .owl-nav [class*='owl-']:hover, .owl-full.owl-nav-dark .owl-nav [class*='owl-']:focus {
  color: var(--primary-color, #445f84); }

.owl-simple .owl-nav [class*='owl-']:not(.disabled):hover {
  color: var(--primary-color, #445f84); }

.owl-simple.owl-light .owl-dots .owl-dot:hover span {
  border-color: var(--primary-color, #445f84);
  background: var(--primary-color, #445f84); }

.owl-simple.owl-light .owl-dots .owl-dot.active span {
  border-color: var(--primary-color, #445f84);
  background: var(--primary-color, #445f84); }

.quickView-content .owl-theme.owl-light .owl-nav [class*='owl-'] {
  border: none;
  font-size: 3rem;
  color: #fff; }
  .quickView-content .owl-theme.owl-light .owl-nav [class*='owl-']:hover, .quickView-content .owl-theme.owl-light .owl-nav [class*='owl-']:focus {
    color: var(--primary-color, #445f84);
    background-color: transparent; }

.quickView-content .details-action-wrapper .btn-product:hover span, .quickView-content .details-action-wrapper .btn-product:focus span {
  color: var(--primary-color, #445f84);
  box-shadow: 0 1px 0 0 var(--primary-color, #445f84); }

.quickView-content .product-details-action .btn-cart {
  transition: color .3s; }
  .quickView-content .product-details-action .btn-cart:hover, .quickView-content .product-details-action .btn-cart:focus {
    border-color: var(--primary-color, #445f84);
    background-color: var(--primary-color, #445f84);
    color: #fff; }

.quickView-content .btn-wishlist,
.quickView-content .btn-compare {
  border: none; }
  .quickView-content .btn-wishlist:before,
  .quickView-content .btn-compare:before {
    color: var(--primary-color, #445f84); }
  .quickView-content .btn-wishlist:hover, .quickView-content .btn-wishlist:focus,
  .quickView-content .btn-compare:hover,
  .quickView-content .btn-compare:focus {
    color: var(--primary-color, #445f84);
    background-color: transparent; }
    .quickView-content .btn-wishlist:hover span, .quickView-content .btn-wishlist:focus span,
    .quickView-content .btn-compare:hover span,
    .quickView-content .btn-compare:focus span {
      color: var(--primary-color, #445f84); }

.quickView-content .btn-fullscreen:hover,
.quickView-content .btn-fullscreen:focus {
  color: #fff;
  background-color: var(--primary-color, #445f84); }

.quickView-content .product-left .carousel-dot.active img {
  opacity: 1;
  box-shadow: 0 0 0 1px var(--primary-color, #445f84); }

.quickView-content .product-left .carousel-dot:hover img,
.quickView-content .product-left .carousel-dot:focus img {
  opacity: 1; }

.newsletter-popup-container .banner-title span {
  color: var(--primary-color, #445f84); }

/* table-cart */
.table-cart .product-row .product .product-title {
  color: var(--primary-color, #445f84); }
  .table-cart .product-row .product .product-title a {
    color: var(--primary-color, #445f84); }
    .table-cart .product-row .product .product-title a:hover, .table-cart .product-row .product .product-title a:focus {
      color: var(--secondary-color, #55739d); }

.dropdown-cart-products .product-title {
  color: var(--primary-color, #445f84); }
  .dropdown-cart-products .product-title a {
    color: var(--primary-color, #445f84); }
    .dropdown-cart-products .product-title a:hover, .dropdown-cart-products .product-title a:focus {
      color: var(--secondary-color, #55739d); }

.page-content .container.static_page ul {
  list-style: inside;
  margin-left: 20px;
  margin-top: 20px; }

.btn-product:hover::before, .btn-product:focus::before {
  color: var(--primary-color, #445f84) !important; }
